.ul-b4__box {
    width: 5rem;
    height: 5rem;
    background-color: $gray-400;
    display: inline-block;
    margin: 0 5px;
}
//border add
.ul-b4__border {
    border: 1px solid $indigo;
}
.ul-b4__border-top {
    border-top: 1px solid $indigo;
}
.ul-b4__border-right {
    border-right: 1px solid $indigo;
}
.ul-b4__border-bottom {
    border-bottom: 1px solid $indigo;
}
.ul-b4__border-left {
    border-left: 1px solid $indigo;
}
.ul-b4-utilities__code pre {
    margin: 0;
    padding: 0;
    font-size: 15px;
}
.ul-b4-utilities__code {
    padding: 25px 10px;
    background-color: $gray-200;
}
// border subb
.ul-b4__border-0 {
    border: none;
}
.ul-b4__border-top-0 {
    border-top: none;
    border-bottom: 1px solid $indigo;
    border-right: 1px solid $indigo;
    border-left: 1px solid $indigo;
}
.ul-b4__border-right-0 {
    border-right: none;
    border-bottom: 1px solid $indigo;
    border-left: 1px solid $indigo;
    border-top: 1px solid $indigo;
}
.ul-b4__border-bottom-0 {
    border-bottom: none;
    border-left: 1px solid $indigo;
    border-top: 1px solid $indigo;
    border-right: 1px solid $indigo;
}
.ul-b4__border-left-0 {
    border-bottom: 1px solid $indigo;
    border-left: none;
    border-top: 1px solid $indigo;
    border-right: 1px solid $indigo;
}

//border-color
