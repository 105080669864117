.custom-head-section {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 1rem;
  width: 100%;
}
.btn-outline-secondary {
  span {
    color: var(--color-red) !important;
  }
  border-color: var(--color-red);
  &:hover,
  &:focus,
  &:active {
    background: transparent;
    border-color: var(--color-red) !important;
    box-shadow: none;
  }
}

.nav-pills .nav-link {
  color: var(--color-main);
  &.active {
    color: var(--color-main);
  }
}
.table {
  margin-bottom: 0;
}
.table thead th {
  border-bottom: none;
}
.card-icon-bg-primary {
  [class^="i-"] {
    color: #305dd2;
  }
}
.text-primary {
  color: #305dd2 !important;
}
.btn-link:hover {
  color: #305dd2 !important;
}

.dashboard-card-icon {
  svg {
    width: 56px;
    height: auto;
    path {
      stroke: var(--color-accent);
      stroke-width: 0.8;
    }
  }
}

.pagination-wrapper {
  a:not([href]):not([class]):hover {
    color: white;
  }
}

/**
* Dark Custom Mode
*/
.darkmode {
  .table {
    tr {
      color: rgb(160, 174, 192);
      td {
        a {
          color: #305dd2;
        }
      }
    }
  }
  .table thead th {
    border-bottom: none;
    border-top: 1px solid #2d3748;
  }
  .table td {
    border-top: 1px solid #2d3748;
  }
  .text-muted {
    color: var(--color-heading) !important;
  }

  .nav-pills .nav-link {
    color: var(--color-white);
    &.active {
      color: var(--color-white);
    }
  }
  .my-react-select__control {
    background-color: transparent;
    border-color: #6285e3;
    .my-react-select__single-value,
    .my-react-select__indicator {
      color: #6285e3;
    }
  }
  .my-react-select__indicator-separator,
  .my-react-select__option--is-selected,
  .my-react-select__option:active {
    background-color: #6285e3;
  }
  .my-react-select__menu-list {
    background: var(--color-table-color);
    border: 1px solid var(--color-table-border);
  }
  .my-react-select__option {
    color: #fff;
  }
}
