@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

$lightgray: #ececec !default;
//Fontawesome

i.fa,
i.fas,
i.fal,
i.far {
  font-family: "Font Awesome 5 Pro" !important;
}

.font-normal {
  font-size: 1rem;
}

.font-lg {
  font-size: 1.2rem;
}

.font-xl {
  font-size: 2rem;
}

.mw-75px {
  min-width: 75px;
}

.ml-20 {
  margin-left: 10px;
}

.mxw-200px {
  max-width: 200px;
}
.cursor-pointer {
  cursor: pointer;
}

.table-wrapper {
  border-radius: 1.25rem !important;
  // box-shadow: 0px 0px 32px rgba(123, 97, 255, 0.1);
  box-shadow: 0px 2px 9px #e1e6f5;
  .card-body {
    border-radius: 1.25rem;
    padding: 0;
    background-color: var(--color-table-color);
    .react-bootstrap-table {
      padding: 1rem;
    }
  }
}
.btn-action-table.dropdown-toggle {
  &:after {
    display: none;
  }
}

.bg-table {
  background-color: var(--color-table-color) !important;
}
.card {
  background-color: var(--color-table-color) !important;
  .card-header {
    background-color: var(--color-table-color) !important;
    border-bottom: solid 1px var(--color-border) !important;
  }
}

.card {
  border-radius: 8px;
  background: transparent;
  box-shadow: 0px 2px 9px var(--color-box-shadow);
  .card-header:first-child {
    border-radius: 8px 8px 0 0;
  }
  .card-body {
    background-color: var(--color-table-color);
    border-radius: 8px;
    color: var(--color-card-content);
    .react-bootstrap-table {
      th {
        border-top: none;
        font-weight: 500;
        color: var(--color-table-th-color);
        &[aria-label*="sc"] {
          font-weight: 700;
        }
        font-style: normal;
        font-size: 14px;
        line-height: 20px;
      }
      td {
        color: var(--color-table-text-color);
        border-bottom: 1px solid var(--color-table-border);
        font-weight: 400;
        max-width: 12rem;
        font-size: 13px;
        vertical-align: middle;

        span {
          color: var(--color-table-text-color);
          line-height: 150%;
        }
        .name-title,
        &.flavor a {
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
        }
        .name-subtitle {
          font-size: 0.75rem;
          color: var(--color-table-text-color);
        }
      }
    }
  }
  .card-footer {
    background-color: var(--color-table-color);
    border-color: var(--color-divider);
  }
}
.table-wrapper {
  margin-bottom: 6rem; //this pushes the page longer to serve space the custom pagination
}
.table-header-wrapper {
  .menu-icon {
    background: var(--color-table-color);
    svg path {
      stroke: var(--color-table-title);
    }
    &.svg-fill svg path {
      fill: var(--color-table-title);
    }
  }
  .menu-title {
    color: var(--color-table-title);
  }
}

//custom layout start
.bg-primary {
  background-color: var(--color-main) !important;
}

.bg-primary--text {
  color: $headerLight;
}

.text-colored {
  color: var(--color-card-title);
}

.icon-svg-stroke-black path {
  stroke: $headerColorText;
}
.icon-svg-fill-black path {
  fill: $headerColorText;
}

.title-wrap {
  margin-right: 16px;
  padding: 12px 15px;
  background: var(--color-table-color);
  border-radius: 4px;
  .nav-icon {
    font-size: 16px;
    color: var(--color-app-title);
  }
}

.marketplace-title {
  font-size: 24px !important;
  color: var(--color-app-title);
  margin-bottom: 0;
}

.layout-sidebar-compact .sidebar-left-secondary {
  background: var(--color-sidebar);
  color: var(--color-white);
  .childNav .nav-item {
    > div,
    > a {
      color: var(--color-white) !important;
      padding: 12px 16px !important;
    }
    &:hover {
      background: rgba(116, 146, 203, 0.2) !important;
      .nav-icon {
        color: var(--color-white);
      }
    }
    &.open {
      width: 100%;
      z-index: -1;
      background: rgba(116, 146, 203, 0.2) !important;
      > a {
        background: transparent;
      }
      &:hover {
        background: rgba(116, 146, 203, 0.2) !important;
        > a {
          background: transparent !important;
        }
      }
    }
    .item-name {
      font-weight: 400 !important;
    }
    &:hover a {
      background: rgba(255, 255, 255, 0.1) !important;
    }
  }
}

label.search-label {
  input {
    background-color: var(--color-table-color);
    border: 1px solid var(--color-table-border);
    &::placeholder {
      color: var(--color-heading);
    }
    &.form-control:focus {
      color: var(--color-heading);
    }
    &:hover {
      border-color: var(--color-table-border);
    }
    &:focus {
      box-shadow: none;
    }
  }
}

.main-header .search-bar {
  background-color: var(--color-search-bar);
  border: none;
  input {
    &::placeholder {
      color: var(--color-topbar-element);
    }
  }
}

.main-header .menu-toggle > div {
  background: var(--color-topbar-element) !important;
}

.layout-sidebar-compact div.main-header a,
.main-content-wrap div.main-header a {
  color: var(--color-topbar-element);
}

.main-header .btn-outline-primary {
  color: var(--color-white);
  background-color: var(--color-main);
}

.main-header .header-icon {
  background: none;
}

.card-border {
  box-shadow: none;
  border: 1px solid var(--color-border);
  position: relative;
}

.tab-content-card .tab-content {
  background-color: var(--color-table-color);
  margin-bottom: 2rem;
  box-shadow: 0 5px 10px 1px rgba(0, 0, 0, 0.06), 0 2px 4px rgba(0, 0, 0, 0.08);
}

.language-selector {
  padding: 0.5rem 1rem;
  margin: 0 1rem;
  border-radius: 8px;
  span {
    border-color: var(--color-border) !important;
  }
  a {
    color: var(--color-topbar-element) !important;
    &.active {
      color: var(--color-topbar-element) !important;
      font-weight: 600;
    }
  }
}

#dropdownNotification {
  background: rgba(49, 93, 209, 0.2);
  border-radius: 50%;
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-label-icon {
  display: flex;
  position: absolute;
  left: 14px;
}

.search-label-icon + input {
  padding-left: 48px;
}

.search-label-icon i {
  color: var(--color-card-description);
  font-weight: bold;
  font-size: 18px;
}

.search-in-apps-wrap {
  position: relative;
}

.search-in-apps-input {
  height: 40px;
}

.search-in-apps-input + .search-in-apps {
  opacity: 0;
  margin-top: -3333px;
  transition: margin-top 0s ease;
  transition-delay: 0.25s;
}

.search-in-apps-input:focus + .search-in-apps {
  opacity: 1;
  margin-top: 0;
}

.search-in-apps {
  position: absolute;
  top: 100%;
  text-align: left;
  background: var(--color-table-color);
  width: 100%;
  z-index: 9999;
  max-height: 300px;
  overflow-x: hidden;
  border-top: none;
  &.search-in-apps-active {
    border: 1px solid var(--color-tab-link);
    border-top: none;
  }
}

.search-in-apps .highlight {
  background: #ffa;
  color: #333;
}

.app-result-empty {
  margin: 0;
  padding: 1rem;
  font-weight: bold;
}

.search-in-apps > .app-result-link {
  display: block;
  padding: 0.75rem 0.5rem;
  background-color: transparent;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.search-in-apps > .app-result-link:hover {
  background-color: rgba(116, 146, 203, 0.2) !important;
}

.search-in-apps .app-result-image {
  width: 50px;
  height: auto;
  float: left;
  margin: 0 0.5rem 0.5rem 0;
}

.search-in-apps .app-result-name {
  font-weight: bold;
}

.search-in-apps .app-result-version {
  margin: 0;
  display: block;
  font-weight: normal;
  color: var(--color-card-description);
}

.search-in-apps .app-result-description {
  font-weight: normal;
  color: var(--color-card-description);
}

.list-group-app-categories {
  width: 200px;
}

.app-marketplace-card-list {
  width: 100%;
}

.list-group-app-categories a {
  font-size: 16px;
  background-color: transparent;
  transition: background-color 0.3s ease;
  color: var(--color-card-description);
  .badge {
    color: var(--color-card-description) !important;
    border-color: var(--color-card-description) !important;
  }
}

.list-group-app-categories a:hover {
  background-color: rgba(116, 146, 203, 0.2) !important;
}

.card-app .card-app-img {
  opacity: 1;
  width: 76px;
  height: 76px;
  margin-bottom: 1.5em;
  transition: opacity 0.3s ease-in-out;
  @media screen and (max-width: 639px) {
    margin-bottom: 1em;
    width: 52px;
    height: 52px;
  }
}

.app-marketplace-grid {
  grid-template-columns: repeat(auto-fit, 280px);
  @media screen and (max-width: 991px) {
    text-align: center;
  }
}

.app-marketplace-card {
  width: 268px;
  height: 298px;
  border-radius: 3px !important;
  display: inline-block;
  position: relative;
  cursor: pointer;
  margin-right: 1.5rem;
  margin-bottom: 1.5rem;
  text-align: left;
  .card {
    border-radius: 10px;
    margin-bottom: 1.5rem;
  }
  .card-app {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 298px;
  }
  @media screen and (max-width: 1241px) {
    width: 220px;
  }
  @media screen and (max-width: 991px) and (min-width: 640px) {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  @media screen and (max-width: 639px) {
    height: 248px;
    width: 100%;
    .card-app {
      width: calc(100% - 24px);
      margin: 12px;
      height: 248px;
    }
  }
}

.small-screen-category-list {
  width: 40%;
  @media screen and (max-width: 639px) {
    width: 100%;
  }
}

.app-marketplace-card-body {
  padding: 20px;
  border-radius: 10px !important;
}

.card-app-details {
  transition: transform 0.3s ease-in-out;

  & .app-version,
  .card-app-description {
    color: var(--color-card-description);
  }
}

.card-app .card-app-description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  height: 3em;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.card-app-cta {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 20px;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  @media screen and (max-width: 639px) {
    display: none;
  }
}

.card-app:hover .card-app-img {
  opacity: 0;
}

.card-app:hover .card-app-details {
  transform: translateY(-65%);
}

.card-app:hover .card-app-cta {
  opacity: 1;
}

//Apps Marketplace - end

//custom layout end

[data-size="mw-650"] {
  max-width: 650px !important;
}

#password-strength,
#password-match,
#valid-phone-number,
.password-strength > span,
.email-validation > span {
  display: inline-block;
  color: #fff;
  padding: 1px 20px;
  border-radius: 3px;
  -webkit-transition: background 0.6s ease;
  transition: background 0.6s ease;
}

.password-strength > span,
.email-validation > span {
  margin-left: 1em;
}

label {
  cursor: pointer;
}

label.search-label {
  margin: 0;
}

.white-space-normal {
  white-space: normal;
}

.layout-sidebar-compact {
  &.sidenav-open {
    .main-header {
      width: 100%;
      padding: 0 0 0 $sidebar-left-secondary-width;
    }
  }
  .main-header {
    padding: inherit;
    width: 100%;
    border-bottom: 1px solid var(--color-border);
  }
  .main-content {
    margin-top: 1rem;
    height: 100%;
    min-height: 100%;
  }
  .main-content-wrap {
    background-color: var(--color-bg-color);
    padding-bottom: 70px !important;
    @media (max-width: 991px) {
      padding-bottom: 120px !important;
    }
  }
  &.sidenav-open .main-content-wrap {
    width: calc(100% - #{$sidebar-left-secondary-width});
    background-color: var(--color-bg-color);
  }
}

.layout-sidebar-compact .sidebar-left-secondary .sidebar-close {
  display: none;
}

//\src\app\views\vminstances\layouts\VmInstancesTableView.jsx
.popover-flavor-wrap {
  min-width: 300px;
}

.popover-vminstance-header-flavor {
  font-size: 1rem;
  font-weight: bold;
}

.popover-vminstance-flavor {
  font-size: 0.75rem;
  text-align: center;
}

.popover-vminstance-flavor-icon,
.volume-details-icon,
.firewall-details-icon {
  font-size: 1.75rem;
}

.firewall-detail-card {
  min-height: 129px;
  display: flex;
  align-items: center;
}

label {
  font-size: 0.875rem !important;
  color: var(--color-omni-subtitle) !important;
}

.form-group {
  margin-bottom: 0 !important;
  .form-text {
    margin-top: 0.5rem !important;
  }
  .form-check {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    input[type="radio"],
    input[type="checkbox"] {
      margin-top: 0;
      width: 16px;
      height: 16px;
    }
    label {
      margin-bottom: 0 !important;
      margin-left: 0.5rem;
      font-size: 1rem !important;
    }
  }
}

.form-control {
  color: var(--color-topbar-element);
  font-size: 14px;
  background-color: var(--color-table-color);
  border: 1px solid var(--color-table-border);
  height: fit-content;
  border-radius: 0.2em;
  &:focus {
    color: var(--color-topbar-element);
    background-color: var(--color-table-color);
    border: 1px solid var(--color-tab-link);
    outline: 0;
    box-shadow: none;
  }
  &::placeholder {
    font-weight: 300;
  }
}

select.form-control {
  padding-left: 24px;
  height: 2.5rem;
  font-size: 1rem;
  color: var(--color-topbar-element);
  background-color: var(--color-table-color);
  border: 1px solid var(--color-table-border);
  display: block;
  line-height: 1.3;
  padding: 0.3em 1.4em 0.3em 0.8em;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  border-radius: 0.2em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg fill='none' height='5' viewBox='0 0 9 5' width='9' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath clip-rule='evenodd' d='m1.06806.180504c-.237574-.234122-.630472-.2414753-.877663-.016324-.126621.115443-.190397.26971-.190397.424125 0 .146767.0574141.293535.173173.407654l3.879327 3.823601c.117.11529.27869.18044.44752.18044.16867 0 .33036-.06515.44736-.18044l3.87933-3.823601c.23757-.234122.22996-.606628-.01723-.831779-.24719-.2251513-.64009-.217798-.87766.016324l-3.4318 3.382556z' fill='%23727fa1' fill-rule='evenodd'/%3E%3C/svg%3E");

  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}
/* Hide arrow icon in IE browsers */
select.form-control::-ms-expand {
  display: none;
}
/* Hover style */
select.form-control,
input.form-control {
  &:hover {
    cursor: pointer;
    border-color: var(--color-tab-link);
  }
  &.is-invalid {
    border-color: var(--color-red);
  }
}

/* Set options to normal weight */
select.form-control option {
  font-weight: normal;
}

.border-radius-top-left {
  border-top-left-radius: 0.6rem;
}
.border-radius-top-right {
  border-top-right-radius: 0.6rem;
}

.section-create-instance .area-title {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 1rem;
  width: 100%;
}

.col-region {
  flex: 0 0 100%;
  max-width: 200px;
}

.col-base-disk {
  flex: 0 0 100%;
  max-width: 200px;
}

.col-mobile {
  flex: 0 0 100%;
  max-width: 200px;
}

.scroll-box {
  height: 50vh;
  overflow: auto;
}

.actions-wrap,
.vminstance-actions-wrap,
.volume-actions-wrap {
  display: inline-block;
  padding: 5px;
  clear: both;
}

.vminstance-actions-wrap i,
.volume-actions-wrap i {
  font-size: 1.5rem;
}

.icon-size-button {
  font-size: 1.2rem;
  vertical-align: middle;
}
.icon-size-badge {
  font-size: 6rem;
  vertical-align: middle;
}

.badge {
  background-color: transparent !important;
  padding: 4px 8px;
  border: solid 1px #305dd2;
  color: #305dd2;
  &.badge-success {
    border: solid 1px #14b8a6 !important;
    color: #14b8a6 !important;
  }
  &.badge-dark {
    border: solid 1px #727fa1 !important;
    color: #727fa1 !important;
  }
  &.badge-error {
    border: solid 1px var(--color-red) !important;
    color: var(--color-red) !important;
  }
}

.active-badge {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  &.active-badge-on {
    background: #4dcb8e;
  }
  &.active-badge-off {
    background: grey;
  }
}

.action-btn {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: none;
  &:hover,
  &:active,
  &:focus {
    background: #ffffff !important;
  }
  div {
    display: flex;
  }
}
.firewalls-associate {
  background: #315dd1;
  border-radius: 8px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #fafbfe;
}

.darkmode {
  .action-btn {
    background: #315dd1;
    &:hover,
    &:active,
    &:focus {
      box-shadow: 1px 3px 19px -8px #ffffff;
      background: #315dd1 !important;
    }
    &.console-btn {
      svg {
        path {
          fill: #ffffff;
        }
      }
    }
    &.instance-btn {
      rect,
      circle {
        stroke: #ffffff;
      }
    }
    &.detach {
      svg {
        path {
          stroke: #ffffff;
        }
      }
    }
    &.power-off-btn {
      &:hover,
      &:active,
      &:focus {
        background: #e24151 !important;
      }
      background: #e24151;
      svg {
        path {
          fill: #ffffff;
        }
      }
    }
    &.power-on-btn {
      background: rgb(77, 203, 142);
      &:hover,
      &:active,
      &:focus {
        background: rgb(77, 203, 142) !important;
      }
      svg {
        path {
          stroke: #ffffff;
        }
      }
    }
  }
}

.vm-card-wrapper {
  min-width: 210px;
}

.vm-card {
  background: var(--color-card-background);
  border-radius: 8px;
  height: 64px;
  padding: 12px 8px 12px 10px;
  border: 1px solid var(--color-border);
  margin: 4px;
}

.vm-details-logo {
  width: 96px;
  @media screen and (max-width: 768px) {
    width: 80px;
  }
}

.badge-top-container {
  .badge {
    top: -8px;
    right: -3px;
    border-radius: 50%;
    font-size: 12px;
    padding: 3px;
    line-height: normal;
    font-weight: bold;
    width: 22px;
    max-width: 22px;
    text-align: center;
  }
}

.btn-selector-wrapper {
  span {
    background-color: var(--color-table-border) !important;
    border: none !important;
  }
}
.btn-selector {
  padding: 6px 12px;
  background-color: var(--color-table-color);
  display: flex;
  align-items: center;
  color: $textLightVersion !important;
  transition: all 0.25s ease-in-out;
  border-radius: 4px;
  border: 1px solid var(--color-table-border);
  &:hover {
    color: var(--color-white) !important;
    background-color: var(--color-accent);
    svg path {
      fill: var(--color-white);
    }
  }
  &-left {
    border-radius: 4px 0 0 4px;
  }
  &-right {
    border-radius: 0 4px 4px 0;
  }
  &-delete:hover {
    background-color: #f44336;
  }
  &-blue {
    background-color: var(--color-accent);
    color: var(--color-white) !important;
    box-shadow: 0px 0px 32px rgba(123, 97, 255, 0);
    svg {
      width: 20px;
      height: 20px;
      path {
        fill: var(--color-white);
      }
    }
    &:hover {
      // color: var(--color-accent) !important;
      // background-color: $white;
      box-shadow: 0px 0px 32px rgba(123, 97, 255, 0.4);
    }
  }
}

.vminstance-logo img {
  max-height: 122px;
}

//add button - dashed border
.dashed-border {
  border: 2px dashed #afafaf !important;
}

.dashed-border:hover {
  border: 2px dashed #252525 !important;
}

//for .ul-widget4__item last child
.dashed-border-bottom {
  border-bottom: 1px dashed #dee2e6 !important;
}

// fixed sidebar and header + instance footer
.side-content-wrap {
  position: fixed;
  z-index: 111;
}

.scrollbar-container .sidebar-close {
  cursor: pointer;
}

.layout-sidebar-compact div.main-header,
.main-content-wrap div.main-header {
  position: sticky !important;
  top: 0 !important;
  background: var(--color-topbar);
  color: var(--color-topbar-element);
  border-radius: 0;
  height: 60px;
}

.instance-footer {
  position: sticky;
  bottom: 0;
  margin: -2.5em;
  z-index: 99;
}

.instance-footer {
  border-top: 1px solid #663399;
  border-bottom: 1px solid #663399;
}

.instance-footer .instances-count input {
  max-width: 70px;
}

//general tweaks

div.header hr {
  margin-bottom: 0;
}

.side-nav li a {
  text-decoration: none;
  div {
    div {
      svg {
        width: 22px;
      }
    }
  }
}

.side-nav li a .nav-icon {
  font-size: 20px;
}

.side-nav li a .item-name {
  font-size: 13px;
}

//control-panel/src/app/views/storagevolumes/layouts/StorageVolumesTableView.jsx
.table-layout-auto {
  table-layout: auto !important;
}

//app\views\storagevolumes\instancedetails\StorageVolumeDetails.jsx
.volumes-row,
.firewall-row,
.lb-row {
  .card {
    background-color: var(--color-table-color);
    .card-header {
      color: var(--color-table-text-color);
      background-color: var(--color-table-header);
    }
    button {
      span {
        color: var(--color-white);
      }
    }
    p,
    strong,
    span,
    b {
      color: var(--color-table-text-color);
    }
  }
  .list-group-item {
    background-color: var(--color-table-color);
  }
  .ul-widget2__info.ul-widget4__users-info {
    width: auto;
  }
}

.pagination {
  flex: 0 1 100%;
  grid-gap: 0.75rem;
  li {
    &:not(.active):hover a {
      background: var(--color-table-hover);
    }
    &.page-item:first-child .page-link {
      border-radius: 4px;
    }
    a {
      border: none;
      // padding: 10px 16px;
      border-radius: 6px;
      color: var(--color-pagination);
      background: transparent;
      &.page-link:focus {
        box-shadow: none;
      }
    }
  }
}

//app\views\vminstances\layouts\VmInstancesCardsLayout.jsx
.card-search {
  position: absolute;
  right: 0;
  top: -50px;
  width: 200px;
}

.tab-content .card-search {
  position: relative;
  top: inherit;
  right: inherit;
  float: right;
}

.createvminstance-flavors-slider .slick-slide {
  padding: 3px 0;
}

//.createvminstance-flavors-slider .slick-slide .active
.radio-primary input:checked ~ .flavor-item {
  box-shadow: 0 0 0 1px rgb(102, 51, 153);
}

//CreateVMinstance cards
.section-create-instance .card {
  border: 1px solid transparent;
  transition: border 0.3s ease;
}

//File manager

.table-file-manager td {
  vertical-align: middle;
  border: none;
}

.table-file-manager tr:nth-child(even) {
  background: rgba(250, 250, 250, 0.7);
}

.custom-breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  content: "\000BB";
}

.page-item.active .page-link {
  background-color: var(--color-sidebar);
  border-color: var(--color-sidebar);
}

.btn-back {
  display: inline-block;
  padding: 8px 16px;
  margin-bottom: 16px;
  border: 1px solid var(--color-table-title);
  background-color: var(--color-bg-color);
  border-radius: 8px;
  font-weight: 500;
  color: var(--color-popover-text);
  font-weight: 500;
  transition: all 0.2s ease-in-out;
  &:hover {
    border: 1px solid var(--color-accent);
    background-color: var(--color-accent);
    color: var(--color-white);
    .nav-icon svg path {
      stroke: var(--color-white);
    }
  }
  .nav-icon {
    display: inline-block;
    svg {
      width: 20px;
      height: 20px;
      path {
        stroke: var(--color-popover-text);
      }
    }
  }
}

// OMNI Compute styling
.custom-pagination {
  padding: 0 2rem;
  position: absolute;
  width: 100%;
  margin-top: 2rem;
  left: 0;
  .size-label {
    width: max-content;
    font-weight: 500;
    color: var(--color-heading);
  }
  button {
    border: none;
    background-color: var(--color-table-color) !important;
    color: var(--color-table-text-color) !important;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    border: 1px solid transparent;
    transition: all 0.2s ease-in-out;
    &:hover {
      box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
      border: 1px solid $textLightVersion;
    }
  }
  .dropdown-menu {
    background-color: var(--color-table-color) !important;
    color: var(--color-table-text-color) !important;
    border: 1px solid var(--color-border);
  }
  .pagination {
    justify-content: flex-end;
    .page-item {
      a {
        display: flex;
        align-items: center;
        font-size: 1rem;
        border: none;
        color: $textLightVersion;
        border-radius: 6px;
      }
      &.active .page-link {
        background-color: var(--color-table-color) !important;
        color: var(--color-table-text-color) !important;
        box-shadow: "0px 0px 30px rgb(0 0 0 / 10%)";
      }
    }
  }
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus,
.btn-secondary:focus,
.btn-outline-secondary:focus {
  box-shadow: none;
}

.btn-primary:focus,
.btn-outline-primary:focus {
  box-shadow: none;
}
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  border-color: transparent;
  box-shadow: 0 8px 25px -8px var(--color-main) !important;
}

// omnicompute base new design
.menu-title {
  color: var(--color-omni-header);
  font-weight: 400;
}
.nav-tabs {
  border-bottom: 1px solid var(--color-table-border);
  flex-wrap: nowrap;
  .nav-link {
    padding: 0 0 0.75rem 0;
    min-width: 82px;
    text-align: center;
    margin-right: 1.5rem;
    color: var(--color-tab-link);
    transition: all 0.2s ease-in-out;

    &:hover,
    &:focus {
      border-color: transparent;
      border-bottom: 1px solid var(--color-accent);
    }
    &.active,
    &.show .nav-link {
      border-color: transparent;
      border-left: none;
      border-right: none;
      border-top: none;
      border-bottom: 3px solid var(--color-accent);
      color: var(--color-omni-header);
      font-weight: 900;
      background-color: transparent;
    }
  }
}
.section-wrapper {
  padding: 1rem 1.5rem;
  border-radius: 6px;
  margin-bottom: 1rem;
  background-color: var(--color-topbar);

  &.tabbed-content {
    background-color: var(--color-table-color);
    margin-bottom: 0;
    border-radius: 1.25rem;
    .react-bootstrap-table {
      padding: 0;
    }
    .tab-content {
      padding: 1rem 0 0 0;
      background-color: var(--color-table-color);
    }
    .custom-pagination {
      left: 0;
    }
    .tab-wrapper {
      margin-bottom: 1.5rem;
    }
  }
  &.dns-wrapper {
    border-radius: 1.25rem;
    label {
      color: var(--color-topbar-element);
    }
    input[type="text"] {
      height: 48px;
      padding-left: 24px;
      font-size: 1rem;
      color: var(--color-topbar-element);
      background: var(--color-table-color);
      border: 1px solid var(--color-table-border);
    }
  }
  &#region {
    .card-picker {
      margin-right: 2rem;
    }
  }
  &#hardware {
    .card-picker {
      padding: 1rem 0.25rem !important;
      margin-right: 0;
      .check {
        top: 12px;
      }
    }
  }
  &#instance {
    .nav-pills {
      justify-content: flex-start;
      grid-row-gap: 2vh;
      row-gap: 2vh;
    }
  }
  &#disk {
    .card-picker {
      .disk-icon svg path {
        fill: var(--color-hardware-svg);
      }
    }
  }
  &#auth {
    .card-picker {
      margin-right: -1.3rem;
    }
    .ssh-options {
      .card-picker {
        margin-right: 1.5rem;
      }
    }
    .adm-pass {
      display: flex;
      justify-content: flex-start;
      grid-gap: 4vh;
      width: 50%;
      .password-input {
        flex: 1;
        .label {
          height: 20px;
          font-size: 12px;
          width: max-content;
          line-height: 1;
        }
      }
      .form-group {
        width: 100%;
        input {
          color: var(--color-topbar-element);
          background: var(--color-table-color);
          box-shadow: none;
        }
      }
    }
  }
  &#network-options {
    .vpc-tab {
      .card-picker {
        .body-info {
          margin-right: 2rem !important;
        }
      }
    }
    .tab-content {
      padding-top: 0;
    }
    .btn-firewall-info {
      padding: 4px 12px;
      border-radius: 8px;
      border: none;
      background-color: var(--color-search-bar);
      color: var(--color-table-text-color);
      margin-bottom: 1rem;
    }
  }
  .section-title {
    font-size: 21px;
    font-weight: 400;
    color: var(--color-omni-header);
  }
  .tab-content {
    padding: 1rem 0 0 0;
  }
  .subcard {
    margin-top: 0.5rem;
    border-bottom: 1px solid var(--color-table-border);
    .nav-link.active {
      border-color: transparent;
      border-radius: 0;
      border-bottom: 1px solid var(--color-accent);
      margin-bottom: 0;
      padding: 0 0 12px 0;
      color: var(--color-omni-header);
      font-weight: 500;
      width: fit-content;
    }
  }
  .nav-pills {
    grid-gap: 2vh;
    .nav-link {
      padding: 0;
      background-color: transparent !important;
      transition: all 0.2s ease-in-out;
      border-bottom: 2px solid transparent;
      width: 100%;
      height: 100%;
      .card-picker .check {
        visibility: hidden;
        transition: all 0.2s ease-in-out;
        position: absolute;
        right: 16px;
      }
      &.active {
        background-color: transparent;
        .card-picker {
          border: 1px solid var(--color-accent);
          .check {
            visibility: visible;
            position: absolute;
            right: 16px;
          }
        }
        .disk-icon svg path {
          fill: var(--color-accent) !important;
        }
        .card-picker-selector {
          .title-hardware {
            color: var(--color-omni-header);
          }
        }
      }
    }
  }
  .show .nav-link {
    background-color: transparent;
    border-bottom: none;
  }
  .card-picker {
    padding: 0.5rem 1rem;
    border: 1px solid var(--color-table-border);
    border-radius: 2px;
    width: fit-content;
    transition: all 0.2s ease-in-out;
    margin-right: 0;
    position: relative;
    width: 100%;
    height: 100%;
    img {
      width: 32px;
      object-position: left top;
      object-fit: cover;
      margin-right: 12px;
    }
    .body-info {
      margin-right: 2rem !important;
      &-hardware {
        margin-right: 0;
      }
      .title {
        font-size: 0.92rem;
        color: var(--color-omni-header);
        font-weight: 400;
        margin-bottom: 4px;
        text-transform: capitalize;
        &-hardware {
          font-size: 1rem;
          color: $lightSlateBlue;
          font-weight: 500;
        }
      }
      .subtitle {
        font-size: 0.675rem;
        color: var(--color-omni-subtitle);
      }
    }
    .card-picker-selector {
      min-width: 260px;
      svg {
        height: 32px;
        g {
          fill: var(--color-hardware-svg);
        }
      }
    }
    &.left-icon {
      margin-right: 0;
      border: none;
      border: 1px dashed var(--color-hardware-svg);
      .body-info {
        margin-right: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        .check {
          visibility: visible;
          margin-right: 1.5rem;
          right: 0;
          left: 12px;
        }
      }
    }
    .select-os-version {
      .custom-select {
        padding: 0 1.75rem 0 0;
        border: none;
        background: url("data:image/svg+xml,%3Csvg fill='none' height='5' viewBox='0 0 9 5' width='9' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath clip-rule='evenodd' d='m1.06806.180504c-.237574-.234122-.630472-.2414753-.877663-.016324-.126621.115443-.190397.26971-.190397.424125 0 .146767.0574141.293535.173173.407654l3.879327 3.823601c.117.11529.27869.18044.44752.18044.16867 0 .33036-.06515.44736-.18044l3.87933-3.823601c.23757-.234122.22996-.606628-.01723-.831779-.24719-.2251513-.64009-.217798-.87766.016324l-3.4318 3.382556z' fill='%23727fa1' fill-rule='evenodd'/%3E%3C/svg%3E")
          right 0.75rem center/8px 10px no-repeat;
        color: $lightSlateBlue;
        height: auto;
        &:focus {
          box-shadow: none;
        }
        option {
          color: $black;
        }
      }
      width: 116px;
    }
    .os-icon {
      border-radius: 0;
      height: auto;
      width: auto;
      svg {
        margin-right: 1rem;
        width: 40px;
        height: 40px;
      }
    }
  }
  .nav-hardware-config {
    row-gap: 2vh;
    .nav-link.active {
      .hardware-config-pill {
        border: 1px solid var(--color-accent);
        transition: all 0.2s ease-in-out;
        p {
          color: var(--color-card-text-color-active);
        }
        .config {
          &-header {
            background-color: var(--color-accent);
            p {
              color: $white;
            }
            .check {
              visibility: visible;
              svg {
                circle {
                  fill: $white;
                }
                path {
                  fill: var(--color-accent);
                }
              }
            }
          }
        }
      }
    }
    .hardware-config-pill {
      min-width: 180px;
      justify-content: center;
      display: flex;
      flex-direction: column;
      border: 1px solid var(--color-table-border);
      border-radius: 2px;
      button {
        width: 48px;
        height: 48px;
        font-size: 20px;
        line-height: 0;
        vertical-align: middle;
        border: 0px solid var(--color-table-border);
        border-radius: 0;
        background: var(--color-pricing-counter);
        color: var(--color-card-text-color);
        &.btn-primary:hover,
        &.btn-outline-primary:hover,
        &.btn-primary:focus,
        &.btn-outline-primary:focus {
          box-shadow: none;
          background: #e5ecf1;
        }
        &#min {
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
          border-right-color: var(--color-table-border);
          border-right-style: solid;
          border-right-width: 1px;
        }
        &#plus {
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
          border-left-color: var(--color-table-border);
          border-left-style: solid;
          border-left-width: 1px;
        }
      }
      .counter {
        padding: 0.7rem 1rem;
        background-color: var(--color-table-color);
        p {
          font-size: 1rem;
          margin: 0;
        }
        input {
          background: transparent;
          color: $pastelBlue;
          border: none;
          max-width: 40px;
          font-size: 1rem;
          margin: 0;
          text-align: center;
          &:focus {
            outline: none;
          }
        }
      }
      p {
        margin-bottom: 0;
        font-size: 0.8rem;
        font-weight: 400;
        color: var(--color-card-text-color);
        padding: 4px;
      }
      .config {
        &-header {
          text-transform: capitalize;
          padding: 4px 0;
          display: flex;
          position: relative;
          justify-content: center;
          align-items: center;
          background-color: var(--color-hardware-pill-bg);
          p {
            font-size: 1rem;
            font-weight: 500;
          }
          .check {
            position: absolute;
            right: 16px;
            visibility: hidden;
          }
        }
        &-body {
          padding: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
          .mo-price {
            color: var(--color-card-mo-price);
            span {
              font-size: 1.25rem;
            }
          }
        }
        &-desc {
          padding: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    @media screen and (max-width: 440px) {
      justify-content: center;
      .nav-item {
        width: 100%;
      }
    }
  }
}
.pricing-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 2rem -2rem 0;
  padding: 0rem 4rem;
  z-index: 37;
  background-color: var(--color-topbar);
  box-shadow: 0px -10px 20px rgba(225, 230, 245, 0.496722);
  justify-content: space-between;
  font-size: 0.775rem;
  min-height: 60px;
  .title {
    height: fit-content;
    font-weight: 500;
    margin: auto 1rem auto 0;
    color: var(--color-hardware-svg);
  }
  .btn-counter {
    border: 1px solid var(--color-table-border);
    width: fit-content;
    height: fit-content;
    border-radius: 5px;
    button {
      width: 38px;
      height: 38px;
      font-size: 20px;
      line-height: 0;
      vertical-align: middle;
      border: 0px solid var(--color-table-border);
      border-radius: 0;
      background: var(--color-pricing-counter);
      color: var(--color-card-text-color);
      &.btn-primary:hover,
      &.btn-outline-primary:hover,
      &.btn-primary:focus,
      &.btn-outline-primary:focus {
        box-shadow: none;
        background: #e5ecf1;
      }
      &#min {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-right-color: var(--color-table-border);
        border-right-style: solid;
        border-right-width: 1px;
      }
      &#plus {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        border-left-color: var(--color-table-border);
        border-left-style: solid;
        border-left-width: 1px;
      }
    }
    .counter {
      padding: 0.3rem 2rem;
      background-color: var(--color-table-color);
      align-items: center;
      display: flex;
      p {
        font-size: 1rem;
        margin: 0;
      }
      input {
        background: transparent;
        color: $pastelBlue;
        border: none;
        max-width: 40px;
        font-size: 1rem;
        margin: 0;
        text-align: center;
        &:focus {
          outline: none;
        }
      }
    }
  }
  .divider-vertical {
    width: 1px;
    height: 100%;
    min-height: 60px;
    background: var(--color-border);
  }
  .total-price {
    .total {
      &-leading {
        text-transform: capitalize;
        color: $pastelBlue;
        font-weight: 500;
        margin: 0;
        height: fit-content;
        margin-right: 1.5rem;
      }
      &-info {
        height: fit-content;
        margin: 0;
        font-size: 0.85rem;
        font-weight: 500;
        color: var(--color-accent);
        span {
          font-size: 1.65rem;
          font-weight: 700;
        }
      }
    }
  }
  .btn-purchase {
    border: 0px solid var(--color-border);
    border-radius: 5px;
    background: var(--color-accent);
    text-transform: capitalize;
    font-weight: 500;
    padding: 0.4rem 1.5rem;
    &.btn-primary:hover,
    &.btn-outline-primary:hover,
    &.btn-primary:focus,
    &.btn-outline-primary:focus {
      box-shadow: none;
    }
    svg {
      width: 24px;
      height: 24px;
      margin-right: 1rem;
    }
  }
  @media screen and (max-width: 766px) {
    flex-direction: column !important;
    padding: 8px;
    .divider-vertical {
      display: none;
    }
    .budget {
      margin-top: 16px;
    }
  }
}

.nav-tabs .nav-link {
  border: transparent;
  font-weight: 400;
  &.active {
    font-weight: 500;
  }
}
.popover {
  background: var(--color-toggle-switcher);
  color: var(--color-popover-text);
  .popover-header {
    background: var(--color-toggle-switcher);
    border-bottom: 1px solid var(--color-table-border);
  }
  .arrow::before,
  .arrow::after {
    border-right-color: var(--color-toggle-switcher);
  }
  .popover-body {
    color: var(--color-popover-text);
    p {
      font-size: 13px;
    }
  }
}
.toggle-switch {
  .body-info {
    margin-left: 1rem;
    &-hardware {
      margin-right: 0;
    }
    .title {
      font-size: 0.775rem;
      color: var(--color-omni-header);
      font-weight: 500;
      margin-bottom: 4px;
      &-hardware {
        font-size: 1.125rem;
        color: $lightSlateBlue;
        font-weight: 500;
      }
    }
    .subtitle {
      font-size: 0.775rem;
      color: $lightSlateBlue;
    }
  }
  .custom-switch {
    padding-left: 3.85rem;
  }
  .custom-switch .custom-control-label::before {
    left: -3.25rem;
    width: 2.7rem;
    height: 1.2rem;
    pointer-events: all;
    border-radius: 10.5rem;
    border: none;
    background-color: var(--color-toggle-switcher);
  }
  .custom-switch .custom-control-label::after {
    top: -1px;
    left: -59.5px;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 10.5rem;
    background-color: $white;
    border: 1px solid var(--color-border);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    color: $white;
    border-color: var(--color-accent);
    background-color: var(--color-accent);
  }

  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: $white;
    transform: translateX(2.05rem);
    border: 1px solid var(--color-border);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
  }

  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(4, 28, 79, 0.08);
  }
}

.hide-header {
  .menu-icon,
  .btn-selector {
    display: none !important;
  }
}

.modal-content {
  background-color: var(--color-table-header);
  .modal-header {
    border-bottom: 1px solid var(--color-table-border);
  }
  .modal-title {
    color: var(--color-table-text-color);
  }
  .modal-footer {
    border-top: 1px solid var(--color-table-color);
  }
  .close {
    color: var(--color-table-text-color);
    text-shadow: none;
    &.close:not(:disabled):not(.disabled):hover,
    &.close:not(:disabled):not(.disabled):focus {
      color: $red;
    }
  }
}
// OMNI Compute styling

.selection-cell,
.selection-cell-header {
  vertical-align: middle !important;
}
.region-table {
  .flag {
    width: 2rem;
    object-fit: cover;
  }
}
.btn-action-table {
  border: none;
  background-color: transparent !important;
  .action-wrapper {
    background-color: transparent !important;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &:hover {
    .action-wrapper {
      background-color: var(--color-action-hover) !important;
    }
    svg {
      border-radius: 50%;
      path {
        stroke: #3361cf !important;
      }
    }
    box-shadow: none;
  }
  ~ .dropdown-menu {
    background-color: var(--color-table-dropdown);
    span {
      color: var(--color-table-dropdown-text);
    }
    border: 1px solid var(--color-border);
  }
}
.btn-action-card {
  width: 100%;
  border: 1px solid var(--color-dropdown-border);
  background-color: transparent !important;
  color: var(--color-topbar-element) !important;

  ~ .dropdown-menu {
    width: 100%;
    background-color: var(--color-table-dropdown);
    span {
      color: var(--color-table-dropdown-text);
    }
    border: 1px solid var(--color-border);
  }
}
#btn-omni-add {
  .action-menu {
    top: 12px !important;
    background-color: var(--color-table-dropdown);
    span {
      color: var(--color-table-dropdown-text);
    }
    .dropdown-item:hover,
    .dropdown-item:focus {
      span {
        color: var(--color-table-dropdown-text);
      }
      text-decoration: none;
      background-color: var(--color-table-hover) !important;
    }
  }
}
.action-menu,
.dropdown-menu {
  background-color: var(--color-table-dropdown);
  a {
    font-weight: 500;
    font-size: 13px;
    display: flex;
    align-items: center;
    color: var(--color-table-dropdown-text) !important;
  }
  .action-icon {
    margin-right: 0.5rem;
    width: 28px;
    path {
      fill: #3361cf;
    }
    &.power path {
      fill: $green;
    }
    &.stop path {
      fill: $red;
    }
  }
  span {
    color: var(--color-table-dropdown-text);
  }
  .dropdown-item.active {
    color: var(--color-white) !important;
    background-color: var(--color-main) !important;
  }
  .dropdown-item:hover,
  .dropdown-item:hover:focus {
    &#sign-out {
      color: salmon;
    }
    text-decoration: none;
    color: var(--color-table-title);
    background-color: var(--color-table-hover);
  }
  .dropdown-creator {
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: var(--color-table-dropdown-text);
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    svg {
      g {
        stroke: #b6bed4;
      }
    }
    &:hover,
    &:focus {
      color: var(--color-table-title);
      background-color: var(--color-table-hover);
    }
  }
}
.action-icon {
  svg {
    width: 24px;
    height: 24px;
  }
  &.power path {
    fill: $green;
  }
  &.stop path {
    fill: $red;
  }
}
.float-ip {
  div {
    display: flex;
    align-items: center;
    span {
      margin-right: 8px;
    }
  }
  a {
    visibility: hidden;
  }
}
.table-hover tbody tr:hover {
  background-color: var(--color-table-hover);
  & > .float-ip a {
    visibility: visible;
  }
}
.text-left {
  color: var(--color-omni-header);
}
.number-of-instance {
  margin-left: 0px;
  @media screen and (min-width: 992px) {
    margin-left: 200px;
  }
}
