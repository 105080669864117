@media screen and (min-width: 1600px) {
  .nav-hardware,
  .nav-hardware-config {
    justify-content: start !important;
    .nav-item {
      margin-right: 2rem;
    }
  }
}

@media screen and (min-width: 1440px) {
  .omnicompute .section-wrapper#auth .card-picker {
    margin-right: 0;
  }
}

@media (max-width: 991px) {
  .list-group-app-categories {
    display: none;
  }

  .main-content-wrap {
    padding: 0 1rem !important;
  }
}

@media screen and (max-width: 991px) {
  [data-size="mw-650"] {
    max-width: 85% !important;
  }

  .mobile-padding-0,
  .mobile-padding-0 > .card-body,
  .mobile-padding-0 .tab-content {
    padding: 0 !important;
  }

  .mobile-single-line {
    flex: 0 1 100%;
  }

  .mobile-no-background {
    background: none !important;
  }

  .mobile-no-border {
    border: none !important;
  }

  .mobile-shadow-0,
  .mobile-shadow-0 > .card-body {
    box-shadow: none !important;
  }

  .mobile-margin-right-0 {
    margin-right: 0 !important;
  }

  .mobile-margin-left-0 {
    margin-left: 0 !important;
  }

  .mobile-padding-card-body-top {
    padding-top: 1.5em !important;
    background-color: var(--color-bg-color);
  }

  .mobile-card-search-normalized {
    position: relative;
    top: 0;
  }

  .card-header-tabs {
    margin: 0;
  }

  .instance-footer {
    padding: 1rem 3em !important;
  }
}

@media screen and (max-width: 576px) {
  .section-create-instance .row-region .card {
    max-width: 200px;
    min-width: 150px;
    width: 30%;
  }

  .instance-footer {
    padding: 0 3em !important;
    bottom: -1px;
  }
  .instance-footer .instances-count {
    text-align: center;
    padding: 10px 10px 5px 10px;
  }
  .instance-footer .instances-count input {
    margin: 5px auto 0px auto;
  }
  .instance-footer .instances-price {
    text-align: center;
    padding: 10px 10px 5px 10px;
  }
  .instance-footer .instances-purchase {
    text-align: center;
    padding: 10px;
  }
}

@media screen and (max-width: 540px) {
  .mobile-hide {
    display: none;
  }

  .mobile-show {
    display: block;
  }

  .main-content-wrap {
    padding: 0 1rem !important;
    overflow-x: hidden;
  }

  .mobile-block {
    display: block !important;
  }

  .card-layout .card-search,
  .firewalls-area-wrap .card-search {
    position: absolute;
    right: inherit;
    top: -50px;
    max-width: 200px;
  }

  //.card-layout > .card-body > .card,
  .mobile-margin-top-50 {
    margin-top: 50px;
  }

  .storage-volumes-align-top-items-mobile {
    display: block !important;
    margin-bottom: 0 !important;
  }

  .storage-volumes-align-top-items-mobile .card-search {
    float: none;
    margin: 15px 0 !important;
  }
}

@media (prefers-color-scheme: dark) {
  .layout-sidebar-compact .sidebar-left-secondary,
  .layout-sidebar-compact .sidebar-left {
    box-shadow: none;
  }
  .pagination {
    .page-link:hover {
      color: $white;
    }
  }
  .table-wrapper,
  .card {
    background-color: transparent;
  }
  .omnicompute {
    .pricing-bar {
      box-shadow: 0px -10px 20px rgba(13, 12, 26, 0.5);
    }
  }
}
