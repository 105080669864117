$font-family-heading: "Roboto", sans-serif;
$font-family-base: "Roboto", sans-serif;
$font-size-base: 0.875rem;

// GLOBAL COLORS
$facebook: rgb(55, 101, 201);
$google: rgb(236, 65, 44);
$twitter: rgb(3, 159, 245);
$instagram: rgb(193, 53, 132);
$linkedin: rgb(0, 119, 181);
$dribble: #ea4c89;
$youtube: #c4302b;

$headerLight: #fff;
$headerLightHover: rgb(230, 230, 230);

$headerMenulight: #f9fbfb;
$textLightVersion: #708095;
$iconLightVersion: #9dabbe;

$blueAccent: #315dd1;
$headerColorText: #4f4f4f;
$pricingCounter: #fbfcfd;

$menuTextColor: #fff;

$catalinaBlue: #072572;
$brightGray: #e9eff4;
$pastelBlue: #b6bed4;
$lavender: #e6edff;
$unBlue: #6a89dc;
$lightSlateBlue: #727fa1;
$oxfordBlue: #021c4f;

$omniCloudPrimary: $blueAccent; //new color based on new design
$omniCloudSidebar: #041c4f; //new color based on new design
$omniBackground: #f4f6fc;

:root {
  --color-main: #305dd2;
  --color-white: #fff;
  --color-red: #f44336;
  --color-sidebar: #041c4f;
  --color-active-pagination: #041c4f;
  --color-topbar: #fff;
  --color-topbar-element: #727fa1;
  --color-heading: rgb(18, 24, 40);
  --color-accent: #305dd2;
  --color-bg-color: #eff2fa;
  --color-card-label: #072572;
  --color-border: #e9eff4;
  --color-dropdown-border: #727fa1;
  --color-search-bar: #f9fbfb;
  --color-table-header: #f9fbfb;
  --color-table-title: #021c4f;
  --color-table-border: #e9eff4;
  --color-table-color: #fff;
  --color-table-hover: transparent;
  --color-table-dropdown: #fff;
  --color-table-dropdown-text: #727fa1;
  --color-table-text-color: #4b587a;
  --color-table-th-color: #181c3d;
  --color-omni-header: #072572;
  --color-omni-subtitle: #727fa1;
  --color-tab-link: #021c4f;
  --color-hardware-svg: #b6bed4;
  --color-hardware-pill-bg: #e6edff;
  --color-hardware-pill-header: #fff;
  --color-card-text-color: #727fa1;
  --color-card-text-color-active: #305dd2;
  --color-card-mo-price: #305dd2;
  --color-card-text-color: #305dd2;
  --color-toggle-switcher: #b6bed4;
  --color-popover-text: #0d0d19;
  --color-popover-bg: #f9fbfb;
  --color-pricing-counter: #fbfcfd;
  --color-pagination: #727fa1;
  --color-card-content: rgb(101, 116, 139);
  --color-divider: rgb(230, 232, 240);
  --color-box-shadow: #e1e6f5;
  --accent-color: var(--color-main);
  --color-card-background: #ffffff;
  --color-card-title: #4b587a;
  --color-app-title: #021c4f;
  --color-card-description: #4e5d79;
  --color-action-hover: #eff2fa;
}

:root.darkmode {
  --color-sidebar: #111827;
  --color-active-pagination: #305dd2;
  --color-topbar: #111827;
  --color-topbar-element: #fff;
  --color-heading: rgb(237, 242, 247);
  --color-accent: #305dd2;
  --color-bg-color: #0c0f19;
  --color-card-label: #e9eff4;
  --color-border: #2d3748;
  --color-dropdown-border: #2d3748;
  --color-search-bar: #252432;
  --color-table-header: #111827;
  --color-table-title: #fff;
  --color-table-border: #313049;
  --color-table-color: #111827;
  --color-table-hover: transparent;
  --color-table-dropdown: #151d23;
  --color-table-dropdown-text: #fff;
  --color-table-th-color: #fff;
  --color-table-text-color: #fff;
  --color-omni-header: #e9eff4;
  --color-omni-subtitle: #727fa1;
  --color-tab-link: #8590ad;
  --color-hardware-svg: #727fa1;
  --color-hardware-pill-bg: #2e2e49;
  --color-hardware-pill-header: #fff;
  --color-card-text-color: #727fa1;
  --color-card-text-color-active: #fff;
  --color-card-mo-price: #727fa1;
  --color-toggle-switcher: #605e7b;
  --color-popover-bg: #3a3a41;
  --color-popover-text: #fff;
  --color-pricing-counter: #111827;
  --color-card-content: rgb(160, 174, 192);
  --color-divider: rgb(45, 55, 72);
  --color-box-shadow: #0000003d;
  --color-card-background: #121826;
  --color-card-title: #6285e3;
  --color-app-title: #6285e3;
  --color-card-description: #6b7d9f;
  --color-action-hover: #172035;
}
