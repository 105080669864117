.fc-button {
  .fc-icon {
    font-size: 1rem;
    vertical-align: middle;
    line-height: 0.9;
  }
}
.fc-button-primary {
  color: white;
  background-color: $primary;
  text-transform: capitalize;
  border-color: $primary;
}
