.select2-container {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: inline-block;
    margin: 0;
    position: relative;
    vertical-align: middle;
    width: 100%;
}
.select2-container .select2-container-dropdown {
    position: absolute;
}
.select2-container .select2-selection--single {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    height: 28px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.select2-container .select2-selection--single .select2-selection__rendered {
    display: block;
    padding-left: 8px;
    padding-right: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.select2-container .select2-selection--single .select2-selection__clear {
    position: relative;
}
.select2-container .select2-selection--multiple {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    min-height: 32px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.select2-container .select2-selection--multiple .select2-selection__rendered {
    display: inline-block;
    overflow: hidden;
    padding-left: 8px;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.select2-container .select2-search--inline {
    float: left;
}
.select2-container .select2-search--inline .select2-search__field {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border: none;
    font-size: 100%;
    margin-top: 5px;
    padding: 0;
}
.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
    -webkit-appearance: none;
}
.select2-dropdown {
    background-color: #fff;
    border: 1px solid #aaa;
    border-radius: 4px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: block;
    position: absolute;
    left: -100000px;
    width: 100%;
    z-index: 1051;
}
.select2-results {
    display: block;
}
.select2-results__options {
    list-style: none;
    margin: 0;
    padding: 0;
}
.select2-results__option {
    padding: 6px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.select2-results__option[aria-selected] {
    cursor: pointer;
}
.select2-container--open .select2-dropdown {
    left: 0;
}
.select2-container--open .select2-dropdown--above {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.select2-container--open .select2-dropdown--below {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.select2-search--dropdown {
    display: block;
    padding: 4px;
}
.select2-search--dropdown .select2-search__field {
    padding: 4px;
    width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
    -webkit-appearance: none;
}
.select2-search--dropdown.select2-search--hide {
    display: none;
}
.select2-close-mask {
    border: 0;
    margin: 0;
    padding: 0;
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    min-height: 100%;
    min-width: 100%;
    height: auto;
    width: auto;
    opacity: 0;
    z-index: 99;
    background-color: #fff;
}
.select2-hidden-accessible {
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
}
.select2-container--default .select2-selection--single {
    background-color: #fff;
    border: 1px solid #aaa;
    border-radius: 4px;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #444;
    line-height: 28px;
}
.select2-container--default .select2-selection--single .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: 700;
}
.select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: #999;
}
.select2-container--default .select2-selection--single .select2-selection__placeholder__option {
    display: none;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 26px;
    position: absolute;
    top: 1px;
    right: 1px;
    width: 20px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
    border-color: #888 transparent;
    border-style: solid;
    border-width: 5px 4px 0;
    height: 0;
    left: 50%;
    margin-left: -4px;
    margin-top: -2px;
    position: absolute;
    top: 50%;
    width: 0;
}
.select2-container--default.select2-container--disabled .select2-selection--single {
    background-color: #eee;
    cursor: default;
}
.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
    display: none;
}
.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: transparent transparent #888;
    border-width: 0 4px 5px;
}
.select2-container--default .select2-selection--multiple {
    background-color: #fff;
    border: 1px solid #aaa;
    border-radius: 4px;
    cursor: text;
}
.select2-container--default .select2-selection--multiple .select2-selection__rendered {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    list-style: none;
    margin: 0;
    padding: 0 5px;
    width: 100%;
}
.select2-container--default .select2-selection--multiple .select2-selection__rendered li {
    list-style: none;
}
.select2-container--default .select2-selection--multiple .select2-selection__placeholder {
    color: #999;
    margin-top: 5px;
    float: left;
}
.select2-container--default .select2-selection--multiple .select2-selection__placeholder__option {
    display: none;
}
.select2-container--default .select2-selection--multiple .select2-selection__clear {
    cursor: pointer;
    float: right;
    font-weight: 700;
    margin-top: 5px;
    margin-right: 10px;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice {
    background-color: #e4e4e4;
    border: 1px solid #aaa;
    border-radius: 4px;
    cursor: default;
    float: left;
    margin-right: 5px;
    margin-top: 5px;
    padding: 0 5px;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    color: #999;
    cursor: pointer;
    display: inline-block;
    font-weight: 700;
    margin-right: 2px;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
    color: #333;
}
.select2-container--default.select2-container--focused .select2-selection--multiple {
    border: solid #000 1px;
    outline: 0;
}
.select2-container--default:not(.select2-container--open) .select2-focused .select2-selection--multiple,
.select2-container--default:not(.select2-container--open) .select2-focused .select2-selection--single {
    border: solid #000 1px;
    outline: 0;
}
.select2-container--default.select2-container--disabled .select2-selection--multiple {
    background-color: #eee;
    cursor: default;
}
.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
    display: none;
}
.select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple,
.select2-container--default.select2-container--open.select2-container--above .select2-selection--single {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple,
.select2-container--default.select2-container--open.select2-container--below .select2-selection--single {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.select2-container--default .select2-search--dropdown .select2-search__field {
    border: 1px solid #aaa;
}
.select2-container--default .select2-search--inline .select2-search__field {
    background: 0 0;
    border: none;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-appearance: textfield;
}
.select2-container--default .select2-results > .select2-results__options {
    max-height: 200px;
    overflow-y: auto;
}
.select2-container--default .select2-results__option[role="group"] {
    padding: 0;
}
.select2-container--default .select2-results__option[aria-disabled="true"] {
    color: #999;
}
.select2-container--default .select2-results__option[aria-selected="true"] {
    background-color: #ddd;
}
.select2-container--default .select2-results__option .select2-results__option {
    padding-left: 1em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
    padding-left: 0;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
    margin-left: -1em;
    padding-left: 2em;
}
.select2-container--default
    .select2-results__option
    .select2-results__option
    .select2-results__option
    .select2-results__option {
    margin-left: -2em;
    padding-left: 3em;
}
.select2-container--default
    .select2-results__option
    .select2-results__option
    .select2-results__option
    .select2-results__option
    .select2-results__option {
    margin-left: -3em;
    padding-left: 4em;
}
.select2-container--default
    .select2-results__option
    .select2-results__option
    .select2-results__option
    .select2-results__option
    .select2-results__option
    .select2-results__option {
    margin-left: -4em;
    padding-left: 5em;
}
.select2-container--default
    .select2-results__option
    .select2-results__option
    .select2-results__option
    .select2-results__option
    .select2-results__option
    .select2-results__option
    .select2-results__option {
    margin-left: -5em;
    padding-left: 6em;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: #5897fb;
    color: #fff;
}
.select2-container--default .select2-results__group {
    cursor: default;
    display: block;
    padding: 6px;
    color: gray;
}
select2.material {
    display: inline-block;
    width: 300px;
}
select2.material > .select2-container {
    padding-bottom: 1.29688em;
    vertical-align: inherit;
}
select2.material > .select2-container .selection {
    padding: 0.4375em 0;
    border-top: 0.84375em solid transparent;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
    width: 100%;
    height: auto;
}
select2.material .select2-container--default .select2-selection--multiple,
select2.material .select2-container--default .select2-selection--single {
    width: 100%;
    border: 0;
    border-radius: 0;
    height: 24px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
select2.material .select2-container--default .select2-selection--multiple::before,
select2.material .select2-container--default .select2-selection--single::before {
    content: " ";
    display: block;
    position: absolute;
    bottom: 1.65em;
    background-color: #ddd;
    height: 1px;
    width: 100%;
}
select2.material .select2-container--default .select2-selection--multiple::after,
select2.material .select2-container--default .select2-selection--single::after {
    content: " ";
    display: block;
    position: absolute;
    bottom: 1.63em;
    background-color: #5a419e;
    height: 2px;
    width: 0%;
    left: 50%;
    -webkit-transition: none;
    transition: none;
}
select2.material .select2-container--default .select2-selection--multiple .select2-selection__rendered,
select2.material .select2-container--default .select2-selection--single .select2-selection__rendered {
    padding-left: 1px;
    line-height: inherit;
}
select2.material .select2-container--default .select2-selection--multiple .select2-selection__placeholder,
select2.material .select2-container--default .select2-selection--single .select2-selection__placeholder {
    display: block;
    color: rgba(0, 0, 0, 0.38);
    -webkit-transition: -webkit-transform 0.3s;
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
    position: absolute;
    -webkit-transform-origin: 0 21px;
    transform-origin: 0 21px;
    left: 0;
    top: 20px;
}
select2.material .select2-container--default .select2-container--open {
    left: 0;
    bottom: 1.6em;
}
select2.material .select2-container--default .select2-selection__placeholder__option {
    -webkit-transform: translateY(-1.5em) scale(0.75) perspective(100px) translateZ(0.001px);
    transform: translateY(-1.5em) scale(0.75) perspective(100px) translateZ(0.001px);
    width: 133.33333%;
}
select2.material .select2-container--default .select2-selection__arrow {
    top: 20px;
}
select2.material .select2-container--default .select2-focused .select2-selection--multiple::after,
select2.material .select2-container--default .select2-focused .select2-selection--single::after,
select2.material .select2-container--default.select2-container--open .select2-selection--multiple::after,
select2.material .select2-container--default.select2-container--open .select2-selection--single::after {
    -webkit-transition: width 0.3s cubic-bezier(0.12, 1, 0.77, 1), left 0.3s cubic-bezier(0.12, 1, 0.77, 1);
    transition: width 0.3s cubic-bezier(0.12, 1, 0.77, 1), left 0.3s cubic-bezier(0.12, 1, 0.77, 1);
    width: 100%;
    left: 0;
}
select2.material .select2-container--default .select2-dropdown {
    border-radius: 0;
    border: 0;
    -webkit-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.5);
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.5);
}
select2.material .select2-container--default .select2-results__option--highlighted[aria-selected],
select2.material .select2-container--default .select2-results__option[aria-selected="true"] {
    background-color: rgba(0, 0, 0, 0.04);
    color: #000;
}
select2.material .select2-container--default .select2-results__option[aria-selected="true"] {
    color: #ff5722;
}
select2.material .select2-container--default.select2-container--disabled .select2-selection--multiple,
select2.material .select2-container--default.select2-container--disabled .select2-selection--single {
    background-color: transparent;
}
select2.material .select2-container--default.select2-container--disabled .select2-selection--multiple::before,
select2.material .select2-container--default.select2-container--disabled .select2-selection--single::before {
    background-image: -webkit-gradient(
        linear,
        left top,
        right top,
        color-stop(0, rgba(0, 0, 0, 0.26)),
        color-stop(33%, rgba(0, 0, 0, 0.26)),
        color-stop(0, transparent)
    );
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.26) 0, rgba(0, 0, 0, 0.26) 33%, transparent 0);
    background-size: 4px 1px;
    background-repeat: repeat-x;
    background-color: transparent;
    background-position: 0 bottom;
}
select2.material.ng-invalid.ng-touched .select2-container--default .select2-selection--multiple::after,
select2.material.ng-invalid.ng-touched .select2-container--default .select2-selection--multiple::before,
select2.material.ng-invalid.ng-touched .select2-container--default .select2-selection--single::after,
select2.material.ng-invalid.ng-touched .select2-container--default .select2-selection--single::before {
    background-color: red;
}
select2.material:not(.select2-container--open) .select2-focused .select2-selection--multiple,
select2.material:not(.select2-container--open) .select2-focused .select2-selection--single {
    border: 0;
}
select2.material .select2-subscript-wrapper {
    position: absolute;
    top: calc(100% - 1.72917em);
    font-size: 75%;
}
@supports (-moz-appearance: none) {
    select2.material .select2-container--default .select2-selection--multiple,
    select2.material .select2-container--default .select2-selection--single {
        height: 26px;
    }
}
@supports (-ms-scroll-limit: 0) {
    select2.material .select2-container--default .select2-selection--multiple,
    select2.material .select2-container--default .select2-selection--single {
        height: 25px;
    }
}

/* Support for rtl text, explicit support for Arabic and Hebrew */
*[dir="rtl"] select.form-control,
:root:lang(ar) select.form-control,
:root:lang(iw) select.form-control {
    background-position: left 0.7em top 50%, 0 0;
    padding: 0.6em 0.8em 0.5em 1.4em;
}

/* Disabled styles */
select.form-control:disabled,
select.form-control[aria-disabled="true"] {
    color: graytext;
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22graytext%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
}

select.form-control:disabled:hover,
select.form-control[aria-disabled="true"] {
    border-color: #aaa;
}
