@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "~react-quill/dist/quill.bubble.css";
@import "~react-quill/dist/quill.snow.css";
@import "~react-quill/dist/quill.core.css";
@import "~react-datetime/css/react-datetime.css";
@import "~react-notifications/lib/notifications.css";
@import "~react-datepicker/dist/react-datepicker.css";
@import "~rc-slider/assets/index.css";
@import "~rc-tooltip/assets/bootstrap.css";
@import "~ladda/dist/ladda.min.css";
@import "~sweetalert2/src/sweetalert2.scss";
@import "~react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
@import "~react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

@import "../../assets/fonts/iconsmind/iconsmind.css";

@import "variables";
@import "themes/lite-blue.scss";
@import "~bootstrap/scss/bootstrap.scss";
@import "bootstrap-rtl.scss";
@import "globals/globals";
@import "additional/index.scss";
@import "additional/select.scss";
@import "responsive";
